<script setup lang="ts">
const props =defineProps<{
  isActive?: boolean
}>();

const classes = computed(() => ({
  'active': props.isActive
}));

</script>

<template>
  <svg class="ham hamRotate ham1" :class="classes" viewBox="0 0 100 100" width="80">
    <path
        class="line top"
        d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"/>
    <path
        class="line middle"
        d="m 30,50 h 40"/>
    <path
        class="line bottom"
        d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"/>
  </svg>
</template>

<style>
.ham {
  width: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes hamRotateForward {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(45deg); }
}

@keyframes hamRotateBackward {
  0% { transform: rotate(45deg); }
  100% { transform: rotate(0deg); }
}

.hamRotate {
  animation: hamRotateBackward 400ms forwards;
}

.hamRotate.active {
  animation: hamRotateForward 400ms forwards;
}

.line {
  fill: none;
  stroke: currentColor;
  stroke-width: 5.5;
  stroke-linecap: round;
}

@keyframes ham1TopForward {
  0% { stroke-dasharray: 40 139; stroke-dashoffset: 0; }
  100% { stroke-dasharray: 40 139; stroke-dashoffset: -98px; }
}

@keyframes ham1TopBackward {
  0% { stroke-dasharray: 40 139; stroke-dashoffset: -98px; }
  100% { stroke-dasharray: 40 139; stroke-dashoffset: 0; }
}

@keyframes ham1BottomForward {
  0% { stroke-dasharray: 40 180; stroke-dashoffset: 0; }
  100% { stroke-dasharray: 40 180; stroke-dashoffset: -138px; }
}

@keyframes ham1BottomBackward {
  0% { stroke-dasharray: 40 180; stroke-dashoffset: -138px; }
  100% { stroke-dasharray: 40 180; stroke-dashoffset: 0; }
}

.ham1 .top {
  animation: ham1TopBackward 400ms forwards;
}

.ham1 .bottom {
  animation: ham1BottomBackward 400ms forwards;
}

.ham1.active .top {
  animation: ham1TopForward 400ms forwards;
}

.ham1.active .bottom {
  animation: ham1BottomForward 400ms forwards;
}
</style>